import {
  createContext,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import {
  TArtist,
  TBookAppointment,
  TBookAppointmentResponse,
  TLocation,
  TPossibleAppointment,
  TPossibleAppointmentDefaultArtists,
  TService
} from "types";
import { IDesiredAppointment, IPerson } from "interfaces";
import {
  calculateTotalPrice,
  IBookAppointmentFull,
  IBookAppointmentServiceFull,
  isSelectedService
} from "./cart-provider.utils";
import { schedulingBufferMinutes } from "../utils/constants";
import { useUser } from "./user";

export interface ICartContextInterface {
  possibleAppointment: TPossibleAppointment[] | null;
  availableTimes: string[] | undefined;
  activePersonsNumber: number;
  cart: IBookAppointmentFull;
  multiPerson: number;
  totalPrice: number;
  mainArtistId?: string;
  bookedAppointmentResponse: TBookAppointmentResponse | null;
  setPossibleAppointment: (
    possibleAppointment:
      | TPossibleAppointment[]
      | null
      | SetStateAction<TPossibleAppointment[] | null>
  ) => void;
  addActivePerson: () => void;
  selectService: (
    service: TService,
    personIndex: number,
    addOns?: string[],
    isEdit?: boolean
  ) => void;
  selectServices: (services: IBookAppointmentServiceFull[]) => void;
  copyFromYou: (index: number) => void;
  getDesiredAppointmentBody: () => IDesiredAppointment;
  removeActivePerson: () => void;
  selectMainArtist: (artistId: string) => void;
  applySelectedArtist: (services: IBookAppointmentServiceFull[]) => void;
  autoSelectArtists: () => void;
  selectTime: (time: string) => void;
  setCurrentLocation: (location: TLocation) => void;
  getBookAppointmentBody: () => TBookAppointment;
  setBookedAppointmentResponse: (
    bookedAppointment: TBookAppointmentResponse
  ) => void;
  resetEverything: () => void;
  resetFromServicesScreen: () => void;
  setServices: (services: TService[]) => void;
  setArtists: (artists: TArtist[] | SetStateAction<TArtist[]>) => void;
  setAddOnsWithCompleteData: (addons: TService[]) => void;
  artists: TArtist[];
  services: TService[];
  addOnsWithCompleteData: TService[];
  setBookingAsUnavailable: () => void;
}

interface ICartProviderProps {
  children: ReactNode;
}

const CartStateContext = createContext<ICartContextInterface | undefined>(
  undefined
);

const defaultPersonNumber = 1;
const defaultMultiPersonNumber = 1;
const emptyBookAppointmentFull = {
  start_time: "",
  end_time: "",
  location: null,
  services: [],
  payment_method_id: "",
  bookingUnavailable: true
};

export function CartProvider({ children }: ICartProviderProps): JSX.Element {
  const [activePersonsNumber, setActivePersonsNumber] =
    useState<number>(defaultPersonNumber);
  const [multiPerson, setMultiPerson] = useState<number>(defaultPersonNumber);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [possibleAppointment, setPossibleAppointment] = useState<
    TPossibleAppointment[] | null
  >(null);
  const [cart, setCart] = useState<IBookAppointmentFull>(
    emptyBookAppointmentFull
  );
  const [artists, setArtists] = useState<TArtist[]>([]);
  const [services, setServices] = useState<TService[]>([]);
  const [addOnsWithCompleteData, setAddOnsWithCompleteData] = useState<
    TService[]
  >([]);
  const [bookedAppointmentResponse, setBookedAppointmentResponse] =
    useState<TBookAppointmentResponse | null>(null);
  const [availableTimes, setAvailableTimes] = useState<string[]>();
  const [mainArtistId, setMainArtistId] = useState<string>();
  const { user } = useUser();

  const getBookingServicesWithAssignedArtists = (
    defaultPermutations: TPossibleAppointmentDefaultArtists[] | null
  ): IBookAppointmentServiceFull[] => {
    let newServices = JSON.parse(JSON.stringify(defaultPermutations));

    newServices = newServices?.map(
      (service: IBookAppointmentServiceFull, index: number) => {
        service.artist = defaultPermutations?.[index];
        if (defaultPermutations?.[index]?.service) {
          service.service = services?.find(
            (service) => service.id === defaultPermutations[index].service
          ) || {
            id: "",
            company_id: "",
            name: "",
            price: 0,
            choice_is_required: false,
            is_addon: false,
            category: "OTHER"
          };
        }
        service.addOns = defaultPermutations?.[index].add_ons;
        return service;
      }
    );
    return newServices;
  };

  useEffect(() => {
    if (possibleAppointment) {
      const oneArtistTimes = possibleAppointment
        .filter(
          (appointment) =>
            appointment.default_permutations
              .flat()
              .filter(
                (artist) =>
                  artist.artist_id === mainArtistId && artist.personIndex === 0
              ).length > 0
        )
        .map((appointment) => appointment.start_time);

      setAvailableTimes([...oneArtistTimes]);
    }
  }, [cart.services, mainArtistId, possibleAppointment]);

  function setCurrentLocation(location: TLocation): void {
    if (location.id !== cart.location?.id) {
      setActivePersonsNumber(defaultPersonNumber);
      setMultiPerson(defaultMultiPersonNumber);
      setTotalPrice(0);
      setPossibleAppointment(null);
      setBookedAppointmentResponse(null);
      setCart({
        ...emptyBookAppointmentFull,
        location
      });
    } else {
      setCart({
        ...cart,
        location
      });
    }
    setMultiPerson(location.multi_person || defaultPersonNumber);
  }

  function _addActivePerson(): void {
    setActivePersonsNumber((prevValue: number) => {
      return prevValue < multiPerson ? prevValue + 1 : prevValue;
    });
  }

  function _removeActivePerson(): void {
    setActivePersonsNumber((prevValue) => {
      let newServices = JSON.parse(JSON.stringify(cart.services));
      if (prevValue > 1) {
        newServices = newServices.filter(
          (service: IBookAppointmentServiceFull) =>
            service.personIndex !== prevValue - 1
        );
        setCart({ ...cart, services: newServices });

        return prevValue - 1;
      }
      return prevValue;
    });
  }

  function selectService(
    _service: TService,
    _personIndex: number,
    _add_ons?: string[],
    _isEdit?: boolean
  ): void {
    let newCartServices = [];

    if (_isEdit) {
      const copyOfCartServices = JSON.parse(JSON.stringify(cart.services));
      const indexOfUpdatingElement = copyOfCartServices.findIndex(
        (service: IBookAppointmentServiceFull) =>
          service.personIndex === _personIndex &&
          service.service.id === _service.id
      );
      copyOfCartServices[indexOfUpdatingElement] = {
        service: _service,
        personIndex: _personIndex,
        addOns: _add_ons
      };
      setCart({
        ...cart,
        services: copyOfCartServices
      });
      return;
    }

    if (isSelectedService(cart, _service.id, _personIndex)) {
      newCartServices = cart.services.filter(
        (
          service: IBookAppointmentServiceFull,
          index: number,
          array: IBookAppointmentServiceFull[]
        ) =>
          !array.find(
            () =>
              service.personIndex === _personIndex &&
              service.service.id === _service.id
          )
      );
    } else {
      newCartServices = [
        ...cart.services,
        {
          service: _service,
          personIndex: _personIndex,
          addOns: _add_ons
        }
      ];
    }

    setCart({
      ...cart,
      services: newCartServices
    });
  }

  function selectServices(services: IBookAppointmentServiceFull[]): void {
    setCart({
      ...cart,
      services
    });
  }

  function findMostFrequentArtistPermutation(
    data: TPossibleAppointmentDefaultArtists[][],
    artistId: string
  ) {
    let mostFrequentPermutation = null;
    let maxFrequency = 0;

    for (const permutations of data) {
      let frequency = 0;

      for (const perm of permutations) {
        if (perm.artist_id === artistId && perm.personIndex === 0) {
          frequency++;
        }
      }

      if (frequency > maxFrequency) {
        maxFrequency = frequency;
        mostFrequentPermutation = permutations;
      }
    }

    return mostFrequentPermutation;
  }

  const handleMainArtistServiceAutoSelect = (
    currentPossibleAppointmentData: TPossibleAppointment | undefined,
    mainArtistId: string
  ) => {
    let defaultPermutations = currentPossibleAppointmentData
      ? findMostFrequentArtistPermutation(
          currentPossibleAppointmentData.default_permutations,
          mainArtistId
        )
      : [];

    const newServices =
      getBookingServicesWithAssignedArtists(defaultPermutations);

    return newServices;
  };

  function selectMainArtist(artistId: string): void {
    setMainArtistId(artistId);

    if (possibleAppointment && artistId) {
      const currentPossibleAppointmentData = possibleAppointment.find(
        (appointment: TPossibleAppointment) => {
          return appointment.start_time === cart.start_time;
        }
      );
      const newServices = handleMainArtistServiceAutoSelect(
        currentPossibleAppointmentData,
        artistId
      );
      if (!!newServices) {
        setCart({
          ...cart,
          services: newServices
        });
      }
      return;
    }
    autoSelectArtists();
  }

  function autoSelectArtists() {
    if (possibleAppointment) {
      const currentPossibleAppointmentData = possibleAppointment.find(
        (appointment: TPossibleAppointment) => {
          return appointment.start_time === cart.start_time;
        }
      );
      let defaultPermutations = currentPossibleAppointmentData
        ? currentPossibleAppointmentData.default_permutations?.[0]
        : [];

      const newServices =
        getBookingServicesWithAssignedArtists(defaultPermutations);

      let cartObject = { ...cart };

      if (newServices?.length > 0) {
        cartObject.services = newServices;
      }

      setCart(cartObject);
    }
  }

  function applySelectedArtist(services: IBookAppointmentServiceFull[]): void {
    setCart({
      ...cart,
      services
    });
  }

  function copyFromYou(index: number): void {
    const yourServices = cart.services.filter(
      (service: IBookAppointmentServiceFull) => {
        return service.personIndex === 0;
      }
    );

    setCart({
      ...cart,
      services: [
        ...cart.services.filter((service: IBookAppointmentServiceFull) => {
          return service.personIndex !== index;
        }),
        ...yourServices.map((service: IBookAppointmentServiceFull) => {
          return {
            ...service,
            personIndex: index
          };
        })
      ]
    });
  }

  function getDesiredAppointmentBody(): IDesiredAppointment {
    const startTime = new Date();
    startTime.setMinutes(
      startTime.getMinutes() + schedulingBufferMinutes(!!user?.impersonate_name)
    );
    const endTime = new Date();
    endTime.setHours(23, 59, 59);
    endTime.setDate(endTime.getDate() + 7);

    const persons: IPerson[] = [];

    cart.services.forEach((service: IBookAppointmentServiceFull) => {
      if (persons[service.personIndex]) {
        persons[service.personIndex].services.push(service.service.id);
        persons[service.personIndex].service_with_addons?.push({
          service_id: service.service.id,
          addons_ids: service.addOns || [],
          personIndex: service.personIndex
        });
      } else {
        persons[service.personIndex] = {
          services: [service.service.id],
          service_with_addons: [
            {
              service_id: service.service.id,
              addons_ids: service.addOns || [],
              personIndex: service.personIndex
            }
          ]
        };
      }
    });

    return {
      locations: cart.location?.id ? [cart.location?.id] : [],
      startTime,
      endTime,
      persons
    };
  }

  function getBookAppointmentBody(): TBookAppointment {
    return {
      start_time: cart.start_time,
      end_time: cart.start_time,
      location_id: cart.location?.id || "",
      services: cart.services.map((service: IBookAppointmentServiceFull) => {
        return {
          artist_id: service.artist?.artist_id || "",
          personIndex: service.personIndex,
          service_id: service.service.id,
          start_time: service.artist?.start_time,
          end_time: service.artist?.end_time,
          addons_ids: service.addOns
        };
      })
    };
  }

  function selectTime(time: string): void {
    if (possibleAppointment) {
      const currentPossibleAppointmentData = possibleAppointment.find(
        (appointment: TPossibleAppointment) => {
          return appointment.start_time === time;
        }
      );

      if (
        mainArtistId &&
        currentPossibleAppointmentData?.default_permutations
      ) {
        const newServices = handleMainArtistServiceAutoSelect(
          currentPossibleAppointmentData,
          mainArtistId
        );
        if (newServices?.every((service) => !!service.artist)) {
          let cartObject = { ...cart, start_time: time };

          if (newServices?.length > 0) {
            cartObject.services = newServices;
            cartObject.bookingUnavailable = false;
          } else {
            cartObject.bookingUnavailable = true;
          }

          setCart(cartObject);
          return;
        }
      }

      let defaultPermutations = currentPossibleAppointmentData
        ? currentPossibleAppointmentData.default_permutations?.[0]
        : [];

      const newServices =
        getBookingServicesWithAssignedArtists(defaultPermutations);

      let cartObject = { ...cart, start_time: time };

      if (newServices?.length > 0) {
        cartObject.services = newServices;
        cartObject.bookingUnavailable = false;
      } else {
        cartObject.bookingUnavailable = true;
      }

      setCart(cartObject);
    }
  }

  const resetHelper = () => {
    setPossibleAppointment(null);
    setTotalPrice(0);
    setBookedAppointmentResponse(null);
    setArtists([]);
    setServices([]);
    setAddOnsWithCompleteData([]);
    setAvailableTimes([]);
    setMainArtistId("");
  };

  const resetEverything = (): void => {
    setCart(emptyBookAppointmentFull);
    setMultiPerson(defaultPersonNumber);
    setActivePersonsNumber(defaultPersonNumber);
    resetHelper();
  };

  const resetFromServicesScreen = (): void => {
    setCart((prev: IBookAppointmentFull) => ({
      ...emptyBookAppointmentFull,
      location: prev?.location,
      services: prev?.services?.map((item: IBookAppointmentServiceFull) => {
        if (item.artist) {
          item.artist = undefined;
        }
        return item;
      })
    }));
    resetHelper();
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(cart, addOnsWithCompleteData));
  }, [cart, addOnsWithCompleteData]);

  const setBookingAsUnavailable = (): void => {
    setCart({
      ...cart,
      bookingUnavailable: true
    });
  };

  return (
    <CartStateContext.Provider
      value={{
        possibleAppointment,
        availableTimes: availableTimes,
        activePersonsNumber,
        cart,
        multiPerson,
        totalPrice,
        mainArtistId,
        bookedAppointmentResponse,
        setPossibleAppointment,
        addActivePerson: _addActivePerson,
        selectService,
        selectServices,
        copyFromYou,
        getDesiredAppointmentBody,
        removeActivePerson: _removeActivePerson,
        selectMainArtist,
        applySelectedArtist,
        autoSelectArtists,
        selectTime,
        setCurrentLocation,
        getBookAppointmentBody: getBookAppointmentBody,
        setBookedAppointmentResponse: setBookedAppointmentResponse,
        resetEverything,
        resetFromServicesScreen,
        setServices,
        setAddOnsWithCompleteData,
        setArtists,
        artists,
        services,
        addOnsWithCompleteData,
        setBookingAsUnavailable
      }}
    >
      {children}
    </CartStateContext.Provider>
  );
}

export function useCart(): ICartContextInterface {
  const context = useContext(CartStateContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
}
