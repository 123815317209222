import { format, formatRelative } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import enUS from "date-fns/locale/en-US";

const timeFormat = "hh:mm a";
const dateFormat = "eee d MMM";

export const getRelative = (
  dateString: string,
  dFormat: string,
  tFormat: string,
  timezone?: string | null
): string => {
  const timeFormat = tFormat ? `, ${tFormat}` : "";
  const formatRelativeLocale: { [key: string]: string } = {
    lastWeek: dFormat + timeFormat,
    yesterday: "'Yesterday'" + timeFormat,
    today: "'Today'" + timeFormat,
    tomorrow: "'Tomorrow'" + timeFormat,
    nextWeek: dFormat + timeFormat,
    other: dFormat + timeFormat
  };
  const locale = {
    ...enUS,
    formatRelative: (token: string) => formatRelativeLocale[token]
  };

  if (timezone) {
    const date = utcToZonedTime(new Date(dateString), timezone);
    const nowInTimezone = utcToZonedTime(new Date(), timezone);

    return formatRelative(date, nowInTimezone, { locale });
  }

  return formatRelative(new Date(dateString), new Date(), { locale });
};

export const getZonedDate = (
  dateString: string | number,
  timezone?: string | null
) => {
  const date = new Date(dateString);

  if (timezone) {
    return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
  }

  return date;
};

export const getTime = (
  dateString: string | number,
  timezone?: string | null
): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };

  if (timezone) {
    options.timeZone = timezone;
  }

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getRelativeDateTime = (
  dateString: string,
  timezone?: string | null
): string => {
  return getRelative(dateString, dateFormat, timeFormat, timezone);
};

export const getRelativeDate = (
  dateString: string,
  timezone?: string | null
): string => {
  return getRelative(dateString, dateFormat, "", timezone);
};

export const isToday = (date: Date): boolean => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const getDate = (dateString: string, timezone?: string | null) => {
  const date = new Date(dateString);

  if (timezone) {
    const zonedDate = utcToZonedTime(date, timezone);
    return format(zonedDate, "yyyy-MM-dd");
  }

  return format(date, "yyyy-MM-dd");
};

export const getFormattedTime = (
  dateString: string,
  formatString: string,
  timezone?: string | null
) => {
  const date = new Date(dateString);

  if (timezone) {
    const zonedDate = utcToZonedTime(date, timezone);
    return format(zonedDate, formatString);
  }

  return format(date, formatString);
};
